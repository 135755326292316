var render = function () {
  var _vm$form, _vm$form2, _vm$form3, _vm$form4, _vm$form5, _vm$form6, _vm$form7, _vm$form8, _vm$form8$tug_boat, _vm$form$file_storage, _vm$form$file_storage2, _vm$form$file_storage3, _vm$form$file_storage5, _vm$form9, _vm$form10, _vm$form12, _vm$form13, _vm$form15, _vm$form16, _vm$form17, _vm$form18, _vm$form20, _vm$form22, _vm$form23, _vm$form24, _vm$form25, _vm$form26, _vm$form27, _vm$form28, _this$form_realisasi;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "invoice-preview-wrapper"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": _vm.$router.currentRoute.params.id === undefined
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error fetching summary data")]), _c('div', {
    staticClass: "alert-body"
  }, [_c('b-link', {
    staticClass: "alert-link"
  }, [_vm._v(" Halaman ini tidak bisa di refresh atau dibuka melalui direct link atau kembali, ")]), _vm._v(" halaman bersifat one time access atau hanya dapat di akses melalui button pada kolom "), _c('strong', [_vm._v("Details")]), _vm._v(" summary. ")], 1)]), _vm.yo_isLoading ? _c('div', {
    staticClass: "progress-wrapper"
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" Memuat data… " + _vm._s(_vm.yo_isLoadingValue + '%') + " ")]), _c('b-progress', {
    key: _vm.yo_isLoadingValue,
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": _vm.yo_isLoadingValue,
      "variant": "info",
      "max": "100"
    }
  })], 1) : _vm._e(), _vm.invoiceData && _vm.$router.currentRoute.params.id ? _c('b-row', {
    staticClass: "invoice-preview"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "9",
      "md": "8"
    }
  }, [_c('b-card', {
    staticClass: "invoice-preview-card",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', [_c('div', {
    staticClass: "logo-wrapper"
  }, [_c('h3', {
    staticClass: "text-primary invoice-logo"
  }, [_vm._v(" Tug Boat - Pelayanan Kapal ")])]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v("PT. Sarana Abadi Lestari")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v("Kec. Palaran, Kota Samarinda")]), _c('p', {
    staticClass: "card-text mb-25"
  }, [_vm._v(" +1 (123) 456 7891, +44 (876) 543 2198 ")]), _c('p', {
    staticClass: "card-text mb-0"
  }, [_vm._v("Kalimantan Timur 75261")])]), _c('div', {
    staticClass: "mt-md-0 mt-2"
  }, [_c('h4', {
    staticClass: "invoice-title"
  }, [_vm._v(" Summary "), _c('span', {
    staticClass: "invoice-number"
  }, [_vm._v("#" + _vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.id))])]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Date of Application')) + " ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : _vm$form2.created_at)) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Date of Departure')) + " ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime((_vm$form3 = _vm.form) === null || _vm$form3 === void 0 ? void 0 : _vm$form3.tug_sandar_end_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Tanggal Sandar ")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime((_vm$form4 = _vm.form) === null || _vm$form4 === void 0 ? void 0 : _vm$form4.tug_sandar_start_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "BookOpenIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Activity')) + " ")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(_vm._s((_vm$form5 = _vm.form) === null || _vm$form5 === void 0 ? void 0 : _vm$form5.kegiatan))])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "FlagIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Form Status')) + " ")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_c('b-badge', {
    attrs: {
      "variant": _vm.formStatusVariant((_vm$form6 = _vm.form) === null || _vm$form6 === void 0 ? void 0 : _vm$form6.form_status)
    }
  }, [_vm._v(" " + _vm._s((_vm$form7 = _vm.form) === null || _vm$form7 === void 0 ? void 0 : _vm$form7.form_status) + " ")])], 1)]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UserIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Pemilik')) + " ")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.form.pemilik) + " ")])])])])])]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" KAPAL "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-8 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nama Kapal')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s((_vm$form8 = _vm.form) === null || _vm$form8 === void 0 ? void 0 : (_vm$form8$tug_boat = _vm$form8.tug_boat) === null || _vm$form8$tug_boat === void 0 ? void 0 : _vm$form8$tug_boat.name_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor PKK')) + " ")])]), _c('td', {
    staticClass: "pb-50",
    on: {
      "click": function click($event) {
        _vm.form.pemilik !== 'Hulu Migas' ? _vm.copyText(_vm.form.tug_boat.no_pkk_tug_boat) : _vm.copyText(_vm.form.no_pkk_tug_boat);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.form.pemilik !== 'Hulu Migas' ? _vm.form.tug_boat.no_pkk_tug_boat : _vm.form.no_pkk_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('GT')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.gt_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('LOA')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(_vm._s(_vm.form.tug_boat.loa_tug_boat) + " M")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.captain_name_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Captain Telp')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.captain_telp_tug_boat) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Shipping Agent')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.dm_shipping_agent.shipping_name) + " ")])]), (_vm$form$file_storage = _vm.form.file_storage_bunkers[0]) !== null && _vm$form$file_storage !== void 0 && _vm$form$file_storage.total_bunker ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Total Bunker')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatNumber((_vm$form$file_storage2 = _vm.form.file_storage_bunkers[0]) === null || _vm$form$file_storage2 === void 0 ? void 0 : _vm$form$file_storage2.total_bunker) || '-') + " Liter ")])]) : _vm._e(), (_vm$form$file_storage3 = _vm.form.file_storage_bunkers[0]) !== null && _vm$form$file_storage3 !== void 0 && _vm$form$file_storage3.total_bunker ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor Ijin Bunker')) + " ")])]), _c('td', {
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        var _vm$form$file_storage4;
        return _vm.copyText((_vm$form$file_storage4 = _vm.form.file_storage_bunkers[0]) === null || _vm$form$file_storage4 === void 0 ? void 0 : _vm$form$file_storage4.no_ijin_bunker);
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$form$file_storage5 = _vm.form.file_storage_bunkers[0]) === null || _vm$form$file_storage5 === void 0 ? void 0 : _vm$form$file_storage5.no_ijin_bunker) || '-') + " ")])]) : _vm._e()])])])]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "HardDriveIcon"
    }
  }), _vm._v(" Document "), _c('feather-icon', {
    attrs: {
      "icon": "HardDriveIcon"
    }
  })], 1), _c('br'), _c('strong', [_vm._v("[ click name file, to preview.]")])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, _vm._l(_vm.isHaveFile, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": _vm.icons(item),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": item.download_link,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " ")])]);
  }), 1)]), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapIcon"
    }
  }), _vm._v(" Jetty Man "), _c('feather-icon', {
    attrs: {
      "icon": "MapIcon"
    }
  })], 1), _c('br'), _vm._v(" [ Tujuan Kapal : " + _vm._s((_vm$form9 = _vm.form) === null || _vm$form9 === void 0 ? void 0 : _vm$form9.tujuan_kapal_tb) + " ] ")], 1)]), _vm.form.pemilik !== 'Hulu Migas' ? _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-5 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.position_jetty || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Posisi Baris Jetty')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.baris_position_jetty || '-') + " ")])])])])]) : _vm._e(), _c('b-card-body', [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.tug_boat.name_tug_boat))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(_vm._s(this.form_realisasi.rka2))]), _c('br'), _c('strong', [_c('span', [_vm._v("Sandar → Berangkat")])])])])])]), _vm.form.pemilik === 'Hulu Migas' ? _c('b-tabs', {
    staticClass: "tabs-custom",
    attrs: {
      "content-class": "",
      "align": "center"
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "25"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v(" " + _vm._s(_vm.form.tug_boat.name_tug_boat) + " ")])])];
      },
      proxy: true
    }], null, false, 2851881023)
  }, [_c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('NestedIO', {
    key: _vm.form.table_jetty_position,
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0",
    attrs: {
      "uri": "realisasi_posisi_kapal",
      "model-id": _vm.form.id,
      "model": "form_tug_boat",
      "posisi": _vm.jetty_positions,
      "posisi-baris": _vm.jetty_baris_positions,
      "mode-summary": true,
      "payload": {
        model_id: null,
        model: null,
        is_first: null,
        posisi_awal: null,
        posisi_awal_baris: null,
        posisi_skrg: null,
        posisi_skrg_baris: null,
        reason: null,
        waktu_pindah: null
      },
      "column-repeat": [{
        fe_id: 1
      }],
      "d-t-o": [],
      "resolve-fetch": function resolveFetch(response) {
        return response.data.realisasi_posisi_kapals;
      },
      "buttonOkTitle": ""
    }
  })], 1)]), _c('b-tab', {
    attrs: {
      "lazy": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "GitMergeIcon",
            "size": "25"
          }
        }), _c('strong', [_c('span', {
          staticStyle: {
            "font-size": "1.5em"
          }
        }, [_vm._v("Boat Timeline ")])])];
      },
      proxy: true
    }], null, false, 1129164927)
  }, [_c('ChainTimeline', {
    attrs: {
      "barge-or-tug": "tugboat",
      "data-i-d": _vm.form.id
    }
  })], 1)], 1) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
  }, [_c('div', {
    staticClass: "col col-md-9 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Waktu Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.tug_sandar_start_time) || '-') + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Waktu Berangkat')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime(_vm.form.tug_sandar_end_time) || '-') + " ")])])])])])], 1), _c('b-card-body', {
    staticClass: "invoice-padding pb-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UnlockIcon"
    }
  }), _vm._v(" Realisasi "), _c('feather-icon', {
    attrs: {
      "icon": "UnlockIcon"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h5', [_vm._v(_vm._s(_vm.form.tug_boat.name_tug_boat))]), _c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v(_vm._s(_vm.form_realisasi.bunker))]), _c('br'), _c('strong', [_c('span', {
    staticStyle: {
      "@media (min-width": "390px) and (max-width: 768px) {",
      "word-wrap": "break-word",
      "width": "50px",
      "white-space": "normal"
    }
  }, [_vm._v("Mulai Berkegiatan Bunker → Selesai Berkegiatan Bunker")])])])])])]), _c('div', {
    staticClass: "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0 p-0"
  }, [_c('div', {
    staticClass: "col col-md-6 p-0"
  }, [_c('table', {
    staticClass: "mt-2 mt-xl-0 w-100 p-0"
  }, [((_vm$form10 = _vm.form) === null || _vm$form10 === void 0 ? void 0 : _vm$form10.kegiatan) != 'BUNKER' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor RPKRO')) + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        var _vm$form11;
        return _vm.copyText((_vm$form11 = _vm.form) === null || _vm$form11 === void 0 ? void 0 : _vm$form11.no_rpkro_tb);
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$form12 = _vm.form) === null || _vm$form12 === void 0 ? void 0 : _vm$form12.no_rpkro_tb) || '-') + " ")])]) : _vm._e(), _vm.form.pemilik == 'TBBM JETTY 3A' && ((_vm$form13 = _vm.form) === null || _vm$form13 === void 0 ? void 0 : _vm$form13.kegiatan) != 'BUNKER' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Nomor PPK ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        var _vm$form14;
        return _vm.copyText((_vm$form14 = _vm.form) === null || _vm$form14 === void 0 ? void 0 : _vm$form14.no_ppk_tb);
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$form15 = _vm.form) === null || _vm$form15 === void 0 ? void 0 : _vm$form15.no_ppk_tb) || '-') + " ")])]) : _vm._e(), ((_vm$form16 = _vm.form) === null || _vm$form16 === void 0 ? void 0 : _vm$form16.kegiatan) != 'BUNKER' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Disetujui Sandar')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime((_vm$form17 = _vm.form) === null || _vm$form17 === void 0 ? void 0 : _vm$form17.disetujui_time_tb) || '-') + " ")])]) : _vm._e(), ((_vm$form18 = _vm.form) === null || _vm$form18 === void 0 ? void 0 : _vm$form18.kegiatan) != 'BUNKER' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " " + _vm._s(_vm.form.pemilik === 'Hulu Migas' ? '(Kedatangan)' : '') + " ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        var _vm$form19;
        return _vm.copyText((_vm$form19 = _vm.form) === null || _vm$form19 === void 0 ? void 0 : _vm$form19.no_spog_tb);
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$form20 = _vm.form) === null || _vm$form20 === void 0 ? void 0 : _vm$form20.no_spog_tb) || '-') + " ")])]) : _vm._e(), _vm.form.pemilik === 'Hulu Migas' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Nomor SPOG')) + " (Keberangkatan) ")])]), _c('td', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "pb-50",
    attrs: {
      "title": "click to copy"
    },
    on: {
      "click": function click($event) {
        var _vm$form21;
        return _vm.copyText((_vm$form21 = _vm.form) === null || _vm$form21 === void 0 ? void 0 : _vm$form21.no_spog_tb_brk);
      }
    }
  }, [_vm._v(" " + _vm._s(((_vm$form22 = _vm.form) === null || _vm$form22 === void 0 ? void 0 : _vm$form22.no_spog_tb_brk) || '-') + " ")])]) : _vm._e(), ((_vm$form23 = _vm.form) === null || _vm$form23 === void 0 ? void 0 : _vm$form23.kegiatan) != 'BUNKER' ? _c('tr', [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _vm._v(" Waktu Berkegiatan "), _c('feather-icon', {
    attrs: {
      "icon": "AnchorIcon"
    }
  })], 1)], 1)])]) : _vm._e(), ((_vm$form24 = _vm.form) === null || _vm$form24 === void 0 ? void 0 : _vm$form24.kegiatan) != 'BUNKER' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Mulai Kegiatan')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime((_vm$form25 = _vm.form) === null || _vm$form25 === void 0 ? void 0 : _vm$form25.kegiatan_start_time_tb) || '-') + " ")])]) : _vm._e(), ((_vm$form26 = _vm.form) === null || _vm$form26 === void 0 ? void 0 : _vm$form26.kegiatan) != 'BUNKER' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Selesai Kegiatan')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.formatDateTime((_vm$form27 = _vm.form) === null || _vm$form27 === void 0 ? void 0 : _vm$form27.kegiatan_end_time_tb) || '-') + " ")])]) : _vm._e(), ((_vm$form28 = _vm.form) === null || _vm$form28 === void 0 ? void 0 : _vm$form28.kegiatan) != 'BUNKER' ? _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('Total')) + " ")])]), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(this === null || this === void 0 ? void 0 : (_this$form_realisasi = this.form_realisasi) === null || _this$form_realisasi === void 0 ? void 0 : _this$form_realisasi.keg_tb) + " ")])]) : _vm._e()])])])]), _c('b-card-body', [_c('strong', {
    staticClass: "mr-2 mb-2 text-center"
  }, [_c('span', {
    domProps: {
      "textContent": _vm._s("Form Perencanaan Created BY ".concat(_vm.form.created_by))
    }
  })])]), _c('br'), _c('hr', {
    staticClass: "invoice-spacing"
  }), _c('b-card-body', {
    staticClass: "invoice-padding pt-0"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Note: ")]), _c('span', [_vm._v(" It was a pleasure working with you and your team. We hope you will keep us in mind for future shipping. Thank You! ")])])], 1)], 1), _c('b-col', {
    staticClass: "invoice-actions",
    staticStyle: {
      "position": "fixed",
      "right": "0"
    },
    attrs: {
      "cols": "12",
      "md": "4",
      "xl": "3"
    }
  }, [_c('b-card', {
    staticClass: "custom-verification"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "outline-secondary",
      "block": "",
      "to": _vm.$router.currentRoute.params.previous_link
    }
  }, [_vm._v(" Kembali ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mb-75",
    attrs: {
      "variant": "success",
      "block": "",
      "to": {
        name: 'dashboard-monitoring-rrv-tug-boat-view-j3a',
        params: {
          id: _vm.form.id,
          api: 'form_tug_boat',
          previous_link: _vm.$router.currentRoute
        }
      }
    }
  }, [_vm._v(" Realisasikan ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }